<template>
  <div id="newBusinesses" class="modal">
    <div class="modal__content">
      <div class="p-4 text-base font-medium border-b border-gray-200">新增</div>

      <el-form class="p-4" label-position="top" label-width="80px" :model="formData">
        <el-form-item label="商户名称">
          <el-input v-model="formData.merchant_name" placeholder="商户名称"></el-input>
        </el-form-item>
        <el-form-item label="法人姓名">
          <el-input v-model="formData.legal_name" placeholder="法人姓名"></el-input>
        </el-form-item>
        <el-form-item label="商户手机号">
          <el-input v-model="formData.merchant_phone" placeholder="商户手机号"></el-input>
        </el-form-item>
        <el-form-item label="商家App应用">
          <el-select v-model="formData.app_id" placeholder="全部" style="width: 100%">
            <el-option
              v-for="item in appList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="状态">
          <el-radio-group v-model="formData.state">
            <el-radio label="1">启用</el-radio>
            <el-radio label="2">禁用</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="备注">
          <el-input
            v-model="formData.remarks"
            type="textarea"
            :autosize="{
              minRows: 2,
              maxRows: 6,
            }"
            :maxlength="40"
            placeholder="可填写字母、数字，汉字，总长度不要超过40个字符。"
          ></el-input>
        </el-form-item>
      </el-form>

      <div class="px-5 py-3 text-right border-t border-gray-200">
        <button
          type="button"
          class="button w-20 border text-gray-700 mr-1"
          @click="hideProgrammaticallyShowModal()"
        >
          取消
        </button>
        <button type="button" class="button w-20 bg-theme-1 text-white" @click="onSubmit">
          确认
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import request from "@/utils/request";

export default {
  props: {
    appList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      formData: {
        id: "", // 编辑时为必传参数
        merchant_name: "",
        merchant_phone: "",
        legal_name: "",
        remarks: "",
        state: "",
        app_id: "", // 选中的应用id
      },
    };
  },
  methods: {
    hideProgrammaticallyShowModal() {
      cash("#newBusinesses").modal("hide"); // 关闭弹窗
    },
    // 确认
    onSubmit() {
      request({
        url: "/merchant/merchant/addOrEdit",
        method: "POST",
        data: this.formData,
      }).then((res) => {
        if (res.code === 0) {
          this.hideProgrammaticallyShowModal();
          this.$parent.init();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal__content {
  width: 580px;
  border-radius: 12px;
}
.el-form-item {
  margin-bottom: 10px;
}
</style>
